import { createSlice } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { Service } from "practicare/types/service.model";
import { db } from "../../config/firebase";
import { store } from "../store";

let servicesSubscription: any = null;

export interface ServicesState {
  data: Service[];
  updatedAt: string;
}

const initialState: ServicesState = {
  data: [] as Service[],
  updatedAt: Date.now().toString(),
};

export const servicesSlice = createSlice({
  name: "services",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subscribeToServices = () => {
  if (servicesSubscription) {
    return;
  }
  try {
    servicesSubscription = onSnapshot(
      query(collection(db, "services"), where("isDeleted", "!=", true)),
      (data) => {
        const services: any = [];
        data.forEach((doc) => {
          services.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        store.dispatch(
          servicesSlice.actions.setData(orderBy(services, ["name"], ["asc"]))
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default servicesSlice.reducer;
