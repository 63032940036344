import { createSlice } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { TherapyTypes } from "practicare/types/user.model";
import { db } from "../../config/firebase";
import { store } from "../store";

let typesSubscription: any = null;

export interface TypesState {
  data: TherapyTypes[];
  updatedAt: string;
}

const initialState: TypesState = {
  data: [] as TherapyTypes[],
  updatedAt: Date.now().toString(),
};

export const typesSlice = createSlice({
  name: "types",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subscribeToTypes = () => {
  if (typesSubscription) {
    return;
  }
  try {
    typesSubscription = onSnapshot(
      query(collection(db, "theraphyTypes"), where("isDeleted", "!=", true)),
      (data) => {
        const types: any = [];
        data.forEach((doc) => {
          types.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        store.dispatch(
          typesSlice.actions.setData(orderBy(types, ["name"], ["asc"]))
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default typesSlice.reducer;
