/*eslint-disable */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { MarketplaceMerchantData } from "practicare/types/marketplace.model";
import { db } from "../../config/firebase";
import { store } from "../store";

export interface MarketplaceState {
  data: MarketplaceMerchantData[];
  updatedAt: string;
}
// Define initial state
const initialState: MarketplaceState = {
  data: [],
  updatedAt: "",
};

export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<MarketplaceMerchantData[]>) => {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

// ** MarketplaceMerchantData Subscription Function
let subscription: (() => void) | null = null;

export const subscribeToMarketplace = () => {
  if (subscription) {
    return;
  }
  try {
    subscription = onSnapshot(
      query(collection(db, "marketplace"), where("isDeleted", "!=", true)),
      (data) => {
        const mappedData: MarketplaceMerchantData[] = [];
        data.forEach((doc) => {
          mappedData.push({
            ...(doc.data() as MarketplaceMerchantData),
            id: doc.id,
          });
        });
        const orderedData = orderBy(
          mappedData,
          ["user.lastName", "user.firstName"],
          ["asc"]
        );
        store.dispatch(marketplaceSlice.actions.setData(orderedData));
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

export default marketplaceSlice.reducer;
/* eslint-enable */
