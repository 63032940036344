import classNames from "classnames";
import { get } from "lodash";
import { Controller } from "react-hook-form";
import { FormFeedback, Input, Label } from "reactstrap";
export const renderInputField = (
  name: string,
  label: string,
  required: boolean,
  control: any,
  errors: any,
  type: string,
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    field: any
  ) => void | null | Promise<void>,
  isDisabled?: boolean,
  styles?: any,
  placeholder?: string
) => (
  <div
    className={classNames({
      ["mb-1"]: !styles?.hideMargin,
      ["w-50"]: styles?.width50,
      ["w-25"]: styles?.width25,
    })}
  >
    <Label className="form-label" for={name}>
      {label} {required && <span className="text-danger">*</span>}
    </Label>
    <Controller
      control={control}
      name={name}
      render={({ field }: { field: any }) => (
        <Input
          id={name}
          placeholder={placeholder ? placeholder : label}
          disabled={isDisabled}
          invalid={!!get(errors, name + ".message", false)}
          {...field}
          type={type}
          onChange={onChange ? (e) => onChange(e, field) : field.onChange}
        />
      )}
    />
    <FormFeedback>{get(errors, name + ".message", "")}</FormFeedback>
  </div>
);
