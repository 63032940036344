import IntlDropdown from "./intlDropdown";
import { UserDropdown } from "./UserDropdown";

export const NavbarUser = () => {
  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      <UserDropdown />
      <IntlDropdown />
    </ul>
  );
};
