import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy, sortBy } from "lodash";
import moment from "moment-timezone";
import type { Availability } from "practicare/types/availability.model";
import type { CompanyHoliday } from "practicare/types/holidays.model";
import { db } from "../../config/firebase";
import { store } from "../store";

// ** Initial state and subscriptions objects
const holidaysSubscriptions = {
  sub: null as (() => void) | null,
  start: null as Date | null,
};

const companyHolidaysSubscriptions = {
  sub: null as (() => void) | null,
  start: null as Date | null,
};

const userHolidaysSubscriptions = {
  start: null as Date | null,
  end: null as Date | null,
  sub: null as (() => void) | null,
  userId: null as string | null,
};

export interface HolidaysState {
  data: Availability[];
  userHolidays: Availability[];
  companyHolidays: CompanyHoliday[];
  updatedAt: string;
}

// Define initial state
const initialState: HolidaysState = {
  data: [] as Availability[],
  userHolidays: [] as Availability[],
  companyHolidays: [],
  updatedAt: Date.now().toString(),
};

export const holidaysSlice: any = createSlice({
  name: "holidays",
  initialState: initialState,
  reducers: {
    setHolidaysData: (state, action: PayloadAction<Availability[]>) => {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setUserHolidaysData: (state, action: PayloadAction<Availability[]>) => {
      state.userHolidays = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setCompanyHolidays: (state, action: PayloadAction<CompanyHoliday[]>) => {
      state.companyHolidays = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

// ** Action creators from slice
export const { setHolidaysData, setUserHolidaysData, setCompanyHolidays } =
  holidaysSlice.actions;

// ** Subscribe to Time Off Data
export const subscribeToHolidays = (start: Date | null) => {
  if (holidaysSubscriptions.sub) {
    if (holidaysSubscriptions.start !== start) {
      holidaysSubscriptions.sub();
    } else {
      return;
    }
  }
  holidaysSubscriptions.start = start;

  try {
    const constraints = [where("isDeleted", "==", false)];

    if (start) constraints.push(where("startDate", "<=", start));

    holidaysSubscriptions.sub = onSnapshot(
      query(collection(db, "availability"), ...constraints),
      (data) => {
        const holidaysData: Availability[] = [];
        data.forEach((doc) => {
          holidaysData.push({
            ...(doc.data() as Availability),
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate.toDate(),
            createdAt: doc.data().createdAt?.toDate(),
            id: doc.id,
          });
        });

        const filteredHolidays = holidaysData.filter((a) => {
          return (
            a.type?.value === "TIME_OFF" &&
            (start ? moment(a.endDate).isSameOrAfter(start) : true)
          );
        });

        store.dispatch(
          setHolidaysData(orderBy(filteredHolidays, "createdAt", "desc"))
        );
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

// ** Subscribe to User Time Off Data
export const subscribeToUserHolidays = (
  start: Date | null,
  end: Date | null,
  userId: string | null
) => {
  if (
    userHolidaysSubscriptions.sub &&
    (userHolidaysSubscriptions.start !== start ||
      userHolidaysSubscriptions.end !== end ||
      userHolidaysSubscriptions.userId !== userId)
  ) {
    userHolidaysSubscriptions.sub();
  } else {
    return;
  }

  userHolidaysSubscriptions.userId = userId;
  userHolidaysSubscriptions.start = start;
  userHolidaysSubscriptions.end = end;

  try {
    const constraints = [
      where("isDeleted", "==", false),
      where("location.id", "==", ""),
      where("theraphist.id", "==", userId),
    ];

    userHolidaysSubscriptions.sub = onSnapshot(
      query(collection(db, "availability"), ...constraints),
      (data) => {
        const userHolidaysData: Availability[] = [];
        data.forEach((doc) => {
          userHolidaysData.push({
            ...(doc.data() as Availability),
            location: { id: "", name: "" },
            id: doc.id,
          });
        });

        const filteredUserHolidays = userHolidaysData.filter((a) => {
          return (
            !a.isDeleted &&
            (start ? moment(a.endDate).isSameOrAfter(start) : true) &&
            (end ? moment(a.startDate).isSameOrBefore(end) : true)
          );
        });

        store.dispatch(
          setUserHolidaysData(sortBy(filteredUserHolidays, "startDate"))
        );
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

export const subscribeToCompanyHolidays = (start: Date | null) => {
  if (companyHolidaysSubscriptions.sub) {
    if (companyHolidaysSubscriptions.start !== start) {
      companyHolidaysSubscriptions.sub();
    } else {
      return;
    }
  }
  companyHolidaysSubscriptions.start = start;

  try {
    const constraints = [where("isDeleted", "==", false)];

    if (start) constraints.push(where("startDate", "<=", start));

    companyHolidaysSubscriptions.sub = onSnapshot(
      query(collection(db, "companyHolidays"), ...constraints),
      (data) => {
        const holidaysData: CompanyHoliday[] = [];
        data.forEach((doc) => {
          holidaysData.push({
            ...(doc.data() as CompanyHoliday),
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate.toDate(),
            createdAt: doc.data().createdAt?.toDate(),
            id: doc.id,
          });
        });

        store.dispatch(
          setCompanyHolidays(orderBy(holidaysData, "createdAt", "desc"))
        );
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

// ** Exporting the reducer
export default holidaysSlice.reducer;
