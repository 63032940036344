import { Fragment } from "react";

import { NavbarUser } from "./NavbarUser";

import { Menu } from "react-feather";

import { NavItem, NavLink } from "reactstrap";
import { useAuth } from "src/context/auth";
import { AddAppointmentNavButton } from "./AddAppointmentNavButton";
import NavSearch from "./NavSearch";

export const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props;
  const { isAdmin } = useAuth();
  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu me-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={() => setMenuVisibility(true)}
            >
              <Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        {isAdmin && <NavSearch />}
        <AddAppointmentNavButton />
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  );
};
