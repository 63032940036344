// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { Room } from "practicare/types/room.model";
import { db } from "../../config/firebase";
import { store } from "../store";

let roomsSubscription: any = null;

export interface RoomsState {
  data: Room[];
  updatedAt: string;
}

const initialState: RoomsState = {
  data: [] as Room[],
  updatedAt: Date.now().toString(),
};

export const roomsSlice = createSlice({
  name: "rooms",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subscribeToRooms = () => {
  if (roomsSubscription) {
    return;
  }
  try {
    roomsSubscription = onSnapshot(
      query(collection(db, "rooms"), where("isDeleted", "!=", true)),
      (data) => {
        const rooms: any = [];
        data.forEach((doc) => {
          rooms.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        store.dispatch(
          roomsSlice.actions.setData(
            orderBy(rooms, ["location.name", "name"], ["asc"])
          )
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default roomsSlice.reducer;
