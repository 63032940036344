import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { Location } from "practicare/types/location.model";
import { db } from "../../config/firebase";
import { store } from "../store";

export interface LocationsState {
  data: Location[];
  updatedAt: string;
}

let locationSubscription: (() => void) | null = null;

const initialState: LocationsState = {
  data: [],
  updatedAt: "",
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Location[]>) => {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subToLocations = () => {
  if (locationSubscription) {
    return;
  }
  try {
    locationSubscription = onSnapshot(
      query(collection(db, "locations"), where("isDeleted", "!=", true)),
      (data) => {
        const locations: Location[] = [];
        data.forEach((doc) => {
          locations.push({
            ...(doc.data() as Location),
            id: doc.id,
          });
        });
        const orderedLocations = orderBy(locations, ["name"], ["asc"]);
        store.dispatch(locationsSlice.actions.setData(orderedLocations));
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

export default locationsSlice.reducer;
