import { useEffect } from "react";
import { BsCalendarPlus } from "react-icons/bs";
import { Button } from "reactstrap";
import { useAddAppointmentModal } from "src/context/appointmentAddModal";
import { useAuth } from "src/context/auth";
import { subToLocations } from "src/redux/reducers/locations";
import { subscribeToRooms } from "src/redux/reducers/rooms";
import { subscribeToUsersCollection } from "src/redux/reducers/users";

export const AddAppointmentNavButton = () => {
  const { isAdmin } = useAuth();
  const { setModalData } = useAddAppointmentModal();

  useEffect(() => {
    if (isAdmin) {
      subscribeToRooms();
      subToLocations();
      subscribeToUsersCollection();
    }
  }, [isAdmin]);

  if (!isAdmin) {
    return null;
  }
  return (
    <div style={{ marginLeft: "10px" }}>
      <Button
        className="btn-icon"
        color="primary"
        onClick={() => setModalData()}
      >
        <BsCalendarPlus size={16} />
      </Button>
    </div>
  );
};
