// ** Router imports
import { useRoutes } from "react-router-dom"

// ** GetRoutes
import { useLayout } from "../layout/hooks/useLayout"
import { getRoutes } from "./routes"

// ** Hooks Imports

const Router = () => {
  // ** Hooks
  const { layout } = useLayout()

  const allRoutes = getRoutes(layout)

  const routes = useRoutes([...allRoutes])

  return routes
}

export default Router
