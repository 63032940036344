import classNames from "classnames";
import { Controller } from "react-hook-form";
import { FormFeedback, Input, Label } from "reactstrap";
import styled from "styled-components";

const StyledInput = styled(Input)`
  position: absolute;
  right: 50px;
`;
export const renderCheckboxField = (
  name: string,
  label: string,
  control: any,
  errors: any,
  defaultChecked?: boolean,
  styles?: any,
  onChangeFnc?: any
) => (
  <div
    className={classNames({
      ["mb-1"]: !styles?.hideMargin,
      ["w-50"]: styles?.width50,
    })}
    style={{ position: "relative" }}
  >
    <Label className="form-label" for={name}>
      {label}
    </Label>
    <Controller
      control={control}
      name={name}
      render={({ field }: { field: any }) => (
        <StyledInput
          type="checkbox"
          id={name}
          {...field}
          checked={field.value}
          onChange={(e: any) => {
            if (onChangeFnc) {
              onChangeFnc(e);
            }
            field.onChange(e);
          }}
        />
      )}
    />
    <FormFeedback>{errors[name]?.message}</FormFeedback>
  </div>
);
