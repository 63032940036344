// ** Third Party Components
import { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

// ** Reactstrap Imports
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useTenant } from "src/context/tenant";

const langObj: Record<string, string> = {
  en: "English",
  de: "German",
  fr: "French",
  pl: "Polish",
  es: "Spanish",
};

const IntlDropdown: React.FC = () => {
  // ** Hooks
  const { i18n } = useTranslation();
  const { tenantSettings } = useTenant();
  useEffect(() => {
    const prevLang = localStorage.getItem("pclng");
    if (prevLang) {
      i18n.changeLanguage(prevLang);
    } else if (tenantSettings && tenantSettings.defaultLang) {
      i18n.changeLanguage(tenantSettings.defaultLang);
    }
  }, [tenantSettings]);
  // ** Function to switch Language
  const handleLangUpdate = (e: React.MouseEvent, lang: string) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
    localStorage.setItem("pclng", lang);
  };

  return (
    <UncontrolledDropdown tag="li" className="dropdown-language nav-item">
      <DropdownToggle
        tag="a"
        className="nav-link"
        onClick={(e) => e.preventDefault()}
      >
        <ReactCountryFlag
          svg
          className="country-flag flag-icon"
          countryCode={i18n.language === "en" ? "us" : i18n.language}
        />
        {/* <span className="selected-language">{langObj[i18n.language]}</span> */}
      </DropdownToggle>
      <DropdownMenu className="mt-0" end>
        {Object.entries(langObj).map(([langCode, langName]) => (
          <DropdownItem
            href="/"
            tag="a"
            key={langCode}
            onClick={(e) => handleLangUpdate(e, langCode)}
          >
            <ReactCountryFlag
              className="country-flag"
              countryCode={langCode === "en" ? "us" : langCode}
              svg
            />
            <span className="ms-1">{langName}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default IntlDropdown;
