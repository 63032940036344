// ** Redux Imports
import { configureStore } from "@reduxjs/toolkit";
import type { AppointmentDetailsState } from "./reducers/appointmentDetails";
import type { AppointmentsState } from "./reducers/appointments";
import type { AvailabilityState } from "./reducers/availability";
import type { CalendarState } from "./reducers/calendar";
import type { CouponsState } from "./reducers/coupons";
import type { CustomersState } from "./reducers/customers";
import type { FeedState } from "./reducers/feed";
import type { HolidaysState } from "./reducers/holidays";
import type { InvoicesState } from "./reducers/invoices";
import type { LocationsState } from "./reducers/locations";
import type { MessagesState } from "./reducers/messages";
/*eslint-disable */
import type { MarketplaceState } from "./reducers/marketplace";
/* eslint-enable */
import type { NotesState } from "./reducers/notes";
import type { PaymentsState } from "./reducers/payments";
import type { PayrollState } from "./reducers/payroll";
import type { ProblemsState } from "./reducers/problems";
import type { RoomsState } from "./reducers/rooms";
import type { ServicesState } from "./reducers/services";
import type { TenantState } from "./reducers/tenant";
import type { TypesState } from "./reducers/types";
import type { UsersState } from "./reducers/users";
import type { VariantsState } from "./reducers/variants";
import rootReducer from "./root";

export interface AppState {
  rooms: RoomsState;
  layout: any;
  appointments: AppointmentsState;
  users: UsersState;
  coupons: CouponsState;
  navbar: any;
  holidays: HolidaysState;
  feed: FeedState;
  customers: CustomersState;
  locations: LocationsState;
  problems: ProblemsState;
  services: ServicesState;
  types: TypesState;
  variants: VariantsState;
  payments: PaymentsState;
  invoices: InvoicesState;
  tenant: TenantState;
  availability: AvailabilityState;
  notes: NotesState;
  calendar: CalendarState;
  appointmentDetails: AppointmentDetailsState;
  payroll: PayrollState;
  messages: MessagesState;
  marketplace: MarketplaceState;
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export { store };
