import { German } from "flatpickr/dist/l10n/de.js";
import { english } from "flatpickr/dist/l10n/default.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Polish } from "flatpickr/dist/l10n/pl.js";
import type { i18n } from "i18next";
import type { TenantCalendarSettings } from "practicare/types/tenant.model";
import { components } from "react-select";
import { Badge } from "reactstrap";
export const getOptionLabel = (option: any) =>
  option.lastName ? `${option.lastName} ${option.firstName}` : option.name;
export const getOptionValue = (option: any) => option.id;
export const VariantOption = ({ data, ...props }: any) => (
  <components.Option {...props}>
    <span className="text-uppercase" style={{ fontSize: "11px" }}>
      <Badge
        className="ms-auto me-1"
        color={data.isOnline ? "light-primary" : "light-secondary"}
        pill
      >
        {data.isOnline ? "Online" : "Offline"}
      </Badge>
    </span>
    {data.name}
  </components.Option>
);

export const VariantOptionSelected = ({ data, ...props }: any) => (
  <components.SingleValue {...props}>
    <span className="text-uppercase" style={{ fontSize: "11px" }}>
      <Badge
        className="ms-auto me-1"
        color={data.isOnline ? "light-primary" : "light-secondary"}
        pill
      >
        {data.isOnline ? "Online" : "Offline"}
      </Badge>
    </span>
    {data.name}
  </components.SingleValue>
);

export const getStatusColor = (
  settings: TenantCalendarSettings,
  status: string
) => {
  switch (status) {
    case "SCHEDULED":
      return settings?.scheduledColor;
    case "CANCELED":
      return settings?.cancelledColor;
    case "CANCELED-PAID":
      return settings?.cancelledPaidColor;
    case "COMPLETED":
      return settings?.completedColor;
  }
};
export const appointmentStatusOption = ({
  data,
  calendarSettings,
  ...props
}: any) => (
  <components.Option {...props}>
    <span className="text-uppercase">
      <Badge
        className="me-1"
        style={{
          backgroundColor: getStatusColor(calendarSettings, data.value),
        }}
        color={`light-${data?.value}`}
        pill
      >
        {" "}
      </Badge>
    </span>
    {data?.label}
  </components.Option>
);

export const appointmentStatusOptionSelected = ({
  data,
  calendarSettings,
  ...props
}: any) => (
  <components.SingleValue {...props}>
    <span className="text-uppercase">
      <Badge
        className="me-1"
        style={{
          backgroundColor: getStatusColor(calendarSettings, data.value),
        }}
        color={`light-${data?.value}`}
        pill
      >
        {" "}
      </Badge>
    </span>
    {data?.label}
  </components.SingleValue>
);

export const UserServiceVariantOption = ({ data, ...props }: any) => (
  <components.Option {...props}>
    <span className="text-uppercase" style={{ fontSize: "11px" }}>
      <Badge
        className="ms-auto me-1"
        color={data.variant.isOnline ? "light-primary" : "light-secondary"}
        pill
      >
        {data.variant.isOnline ? "Online" : "Offline"}
      </Badge>
    </span>
    {data.variant.name}
  </components.Option>
);

export const UserServiceVariantOptionSelected = ({ data, ...props }: any) => (
  <components.SingleValue {...props}>
    <span className="text-uppercase" style={{ fontSize: "11px" }}>
      <Badge
        className="ms-auto me-1"
        color={data.variant.isOnline ? "light-primary" : "light-secondary"}
        pill
      >
        {data.variant.isOnline ? "Online" : "Offline"}
      </Badge>
    </span>
    {data.variant.name}
  </components.SingleValue>
);

export const selectFlatpickrLocale = (i18n: i18n) => {
  const currentLanguage = i18n.language;
  switch (currentLanguage) {
    case "pl":
      return { ...Polish, firstDayOfWeek: 1 };
    case "en":
      return { ...english, firstDayOfWeek: 1 };
    case "es":
      return { ...Spanish, firstDayOfWeek: 1 };
    case "fr":
      return { ...French, firstDayOfWeek: 1 };
    case "de":
      return { ...German, firstDayOfWeek: 1 };
  }
};

export const AppointmentServiceVariantOption = ({ data, ...props }: any) => (
  <components.Option {...props}>
    <span className="text-uppercase" style={{ fontSize: "11px" }}>
      <Badge
        className="ms-auto me-1"
        color={data.isOnline ? "light-primary" : "light-secondary"}
        pill
      >
        {data.isOnline ? "Online" : "Offline"}
      </Badge>
    </span>
    {data.name}
  </components.Option>
);

export const AppointmentServiceVariantOptionSelected = ({
  data,
  ...props
}: any) => (
  <components.SingleValue {...props}>
    <span className="text-uppercase" style={{ fontSize: "11px" }}>
      <Badge
        className="ms-auto me-1"
        color={data.isOnline ? "light-primary" : "light-secondary"}
        pill
      >
        {data.isOnline ? "Online" : "Offline"}
      </Badge>
    </span>
    {data.name}
  </components.SingleValue>
);
