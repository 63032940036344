import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { orderBy } from "lodash";
import type { Payment } from "practicare/types/payment.model";
import { getCollectionName } from "src/config/utils";
import type { UserState } from "src/context/auth";
import { db } from "../../config/firebase";
import { store } from "../store";

// ** Initial state and subscriptions objects
export const paymentsSubscriptions = {
  sub: null as (() => void) | null,
  start: null as Date | null,
  end: null as Date | null,
  userId: "" as string | null,
  loading: true as boolean,
};

export interface PaymentsState {
  data: Payment[];
  updatedAt: string;
  loading: boolean;
}

// Define initial state
const initialState: PaymentsState = {
  data: [] as Payment[],
  updatedAt: "",
  loading: true,
};

export const paymentsSlice: any = createSlice({
  name: "payments",
  initialState: initialState,
  reducers: {
    setPaymentsData: (state, action: PayloadAction<Payment[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.updatedAt = Date.now().toString();
    },
    setLoading: (state) => {
      state.loading = true;
      state.updatedAt = Date.now().toString();
    },
  },
});

// ** Action creators from slice
export const { setPaymentsData, setLoading, setUserPaymentsData } =
  paymentsSlice.actions;

// ** Subscribe to Time Off Data
export const subscribeToPayments = (
  start: Date | null,
  end: Date | null,
  user: UserState
) => {
  if (!user || !start || !end) {
    return;
  }
  if (paymentsSubscriptions.sub) {
    if (paymentsSubscriptions.start !== start) {
      paymentsSubscriptions.sub();
    } else if (paymentsSubscriptions.end !== end) {
      paymentsSubscriptions.sub();
    } else if (paymentsSubscriptions.userId !== user?.userData?.id) {
      paymentsSubscriptions.sub();
    } else {
      return;
    }
  }
  store.dispatch(setLoading());
  paymentsSubscriptions.start = start;
  paymentsSubscriptions.end = end;
  paymentsSubscriptions.userId = user?.userData?.id || null;

  try {
    const constraints = [];
    if (start) constraints.push(where("dateTime", ">=", start));
    if (end) constraints.push(where("dateTime", "<=", end));
    if (user.userData?.role !== "ADMIN" && user.userData?.id) {
      constraints.push(where("theraphist.id", "==", user.userData?.id));
    }

    const collectionName = getCollectionName(
      "payments",
      store,
      user.userData?.role === "ADMIN"
    );

    paymentsSubscriptions.sub = onSnapshot(
      query(collection(db, collectionName), ...constraints),
      (data) => {
        const paymentsData: Payment[] = [];
        data.forEach((doc) => {
          if (!doc.data().isDeleted) {
            paymentsData.push({
              ...(doc.data() as Payment),
              dateTime: doc.data().dateTime?.toDate(),
              createdAt: doc.data().createdAt?.toDate(),
              id: doc.id,
            });
          }
        });

        paymentsData.forEach((payment) => {
          if (payment.appointment) {
            payment.appointment.dateTime = (
              payment.appointment?.dateTime as Timestamp
            )?.toDate();
          }
        });

        store.dispatch(
          setPaymentsData(orderBy(paymentsData, "createdAt", "desc"))
        );
        paymentsSubscriptions.loading = false;
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

export default paymentsSlice.reducer;
