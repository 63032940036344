import { createSlice } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { CustomerProblems } from "practicare/types/user.model";
import { db } from "../../config/firebase";
import { store } from "../store";

let problemsSubscription: any = null;

export interface ProblemsState {
  data: CustomerProblems[];
  updatedAt: string;
}

const initialState: ProblemsState = {
  data: [] as CustomerProblems[],
  updatedAt: Date.now().toString(),
};

export const problemsSlice = createSlice({
  name: "problems",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subscribeToProblems = () => {
  if (problemsSubscription) {
    return;
  }
  try {
    problemsSubscription = onSnapshot(
      query(collection(db, "customerProblems"), where("isDeleted", "!=", true)),
      (data) => {
        const problems: any = [];
        data.forEach((doc) => {
          problems.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        store.dispatch(
          problemsSlice.actions.setData(orderBy(problems, ["name"], ["asc"]))
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default problemsSlice.reducer;
