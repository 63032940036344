import { doc, onSnapshot } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { db } from "../config/firebase";

// ** Create Context
const ThemeColors = createContext({});

const ThemeContext = ({ children }: any) => {
  // ** State
  const [colors, setColors] = useState({});
  const [tenantSettings, setTenantSettings] = useState({});

  useEffect(() => {
    onSnapshot(doc(db, "config", "publicTenantSettings"), (data) => {
      if (!data.exists) {
        return;
      }
      setTenantSettings({ ...data.data() });
    });
  }, []);

  return (
    <ThemeColors.Provider value={{ colors, tenantSettings }}>
      {children}
    </ThemeColors.Provider>
  );
};

export { ThemeColors, ThemeContext };
