import appointmentDetails from "./reducers/appointmentDetails";
import appointments from "./reducers/appointments";
import availability from "./reducers/availability";
import calendar from "./reducers/calendar";
import coupons from "./reducers/coupons";
import customers from "./reducers/customers";
import feed from "./reducers/feed";
import holidays from "./reducers/holidays";
import invoices from "./reducers/invoices";
import layout from "./reducers/layout";
import locations from "./reducers/locations";
import marketplace from "./reducers/marketplace";
import messages from "./reducers/messages";
import navbar from "./reducers/navbar";
import notes from "./reducers/notes";
import payments from "./reducers/payments";
import payroll from "./reducers/payroll";
import problems from "./reducers/problems";
import rooms from "./reducers/rooms";
import services from "./reducers/services";
import tenant from "./reducers/tenant";
import types from "./reducers/types";
import users from "./reducers/users";
import variants from "./reducers/variants";

const rootReducer = {
  rooms,
  layout,
  users,
  navbar,
  customers,
  locations,
  problems,
  types,
  services,
  variants,
  appointments,
  invoices,
  tenant,
  payments,
  calendar,
  availability,
  notes,
  holidays,
  feed,
  appointmentDetails,
  payroll,
  messages,
  coupons,
  marketplace,
};

export default rootReducer;
