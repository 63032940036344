import { Power } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Avatar } from "../../../components/avatar";
import { useAuth } from "../../../context/auth";

export const UserDropdown = () => {
  const { logout, user, isAdmin } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link"
          onClick={(e) => e.preventDefault()}
        >
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name fw-bold">
              {user?.userData?.firstName} {user?.userData?.lastName}
            </span>
            <span className="user-status">
              {isAdmin ? "Admin" : t("Employee")}
            </span>
          </div>
          {user?.userData?.avatar ? (
            <Avatar
              img={user?.userData?.avatar ? user?.userData?.avatar : ""}
              isCustom
              imgHeight="40"
              imgWidth="40"
            />
          ) : (
            <Avatar
              initials
              imgHeight="40"
              imgWidth="40"
              content={`${user?.userData?.firstName} ${user?.userData?.lastName}`}
            />
          )}
        </DropdownToggle>
        <DropdownMenu end container={"body"}>
          <DropdownItem tag={Link} onClick={() => logout()}>
            <Power size={14} className="me-75" />
            <span className="align-middle">{t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};
