import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { orderBy } from "lodash";
import { db } from "src/config/firebase";
import { store } from "../store";

export const MESSAGE_TYPE = {
  TO_PATIENT: "to_patient",
  TO_LOCATION: "to_location",
  TO_PATIENTS: "to_patients",
} as const;

export const STATUS = {
  PROCESSING: "processing",
  SENT: "sent",
  FAILED: "failed",
  ERROR: "error",
} as const;

interface MessageData {
  id: string;
  [key: string]: any;
}

export interface MessagesState {
  data: {
    sent: MessageData[];
    received: MessageData[];
  };
  total: number;
  params: Record<string, any>;
  allData: {
    sent: MessageData[];
    received: MessageData[];
  };
  selectedMessage: MessageData | null;
  stats: {
    sent: number;
    received: number;
  };
  updatedAt: string;
}

const initialState: MessagesState = {
  data: { sent: [], received: [] },
  total: 0,
  params: {},
  allData: { sent: [], received: [] },
  selectedMessage: null,
  stats: { sent: 0, received: 0 },
  updatedAt: Date.now().toString(),
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessagesSent(state, action: PayloadAction<{ sent: MessageData[] }>) {
      state.data.sent = action.payload.sent;
      state.updatedAt = Date.now().toString();
    },
    setMessagesReceived(
      state,
      action: PayloadAction<{ received: MessageData[] }>
    ) {
      state.data.received = action.payload.received;
      state.updatedAt = Date.now().toString();
    },
  },
  extraReducers: (builder) => {
    builder;
    // .addCase(getAllData.fulfilled, (state, action) => {
    //   state.allData = action.payload;
    // })
    // .addCase(getSentMessages.fulfilled, (state, action) => {
    //   state.data.sent = action.payload.data;
    //   state.total = action.payload.totalPages;
    //   state.params = action.payload.params;
    // })
    // .addCase(getReceivedMessages.fulfilled, (state, action) => {
    //   state.data.received = action.payload.data;
    //   state.total = action.payload.totalPages;
    //   state.params = action.payload.params;
    // })
    // .addCase(getSentMessage.fulfilled, (state, action) => {
    //   state.selectedMessage = action.payload;
    // })
    // .addCase(getReceivedMessage.fulfilled, (state, action) => {
    //   state.selectedMessage = action.payload;
    // });
  },
});

interface MessagesSubscription {
  subSent: (() => void) | null;
  subReceived: (() => void) | null;
  loading: boolean;
}

const messagesSubscription: MessagesSubscription = {
  subSent: null,
  subReceived: null,
  loading: true,
};

const subscribeToMessages = () => {
  messagesSubscription.loading = true;
  try {
    if (!messagesSubscription.subSent) {
      messagesSubscription.subSent = onSnapshot(
        query(collection(db, "messagesSent"), where("isDeleted", "==", false)),
        (snapshot: QuerySnapshot) => {
          const data: MessageData[] = [];
          snapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });

          store.dispatch(
            messagesSlice.actions.setMessagesSent({
              sent: orderBy(data, "createdAt.seconds", "desc"),
            })
          );
        }
      );
    }

    if (!messagesSubscription.subReceived) {
      messagesSubscription.subReceived = onSnapshot(
        query(collection(db, "smsAnswers"), where("isDeleted", "==", false)),
        (snapshot: QuerySnapshot) => {
          const data: MessageData[] = [];
          snapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });

          store.dispatch(
            messagesSlice.actions.setMessagesReceived({
              received: orderBy(data, "createdAt.seconds", "desc"),
            })
          );
        }
      );
    }
  } catch (e) {
    console.error(e);
  }
  messagesSubscription.loading = false;
};

export { messagesSubscription, subscribeToMessages };

export default messagesSlice.reducer;
