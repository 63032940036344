/*eslint-disable */
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeContext } from "./context/ThemeColors";
import { AuthProvider } from "./context/auth";
import { TenantProvider } from "./context/tenant";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <TenantProvider>
          <ThemeContext>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ThemeContext>
        </TenantProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
/* eslint-enable */
