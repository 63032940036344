// ** Vertical Menu Components
import VerticalNavMenuGroup from "./VerticalNavMenuGroup"
import VerticalNavMenuLink from "./VerticalNavMenuLink"
import VerticalNavMenuSectionHeader from "./VerticalNavMenuSectionHeader"

// ** Utils
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "../../../../layout/utils"
import { useContext } from "react"
import { useAuth } from "../../../../context/auth"

const VerticalMenuNavItems = (props) => {
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }
  const { user } = useAuth()

  // ** Render Nav Menu Items
  const RenderNavItems = (props.items || []).map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]
    const role = user?.userData?.role
    if (item.children) {
      return (
        true &&
        (item.requiredPermission || []).indexOf(role) > -1 && (
          <TagName item={item} index={index} key={item.id} {...props} />
        )
      )
    }
    return (
      (item.requiredPermission || []).indexOf(role) > -1 && (
        <TagName key={item.id || item.header} item={item} {...props} />
      )
    )
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
