import { createSlice } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type {
  ServiceVariant,
  UserServiceVariant,
} from "practicare/types/service.model";
import { db } from "../../config/firebase";
import { store } from "../store";

let variantsSubscription: any = null;

export interface VariantsState {
  data: ServiceVariant[];
  userData: UserServiceVariant[];
  updatedAt: string;
}

const initialState: VariantsState = {
  data: [] as ServiceVariant[],
  userData: [] as UserServiceVariant[],
  updatedAt: Date.now().toString(),
};

export const variantsSlice = createSlice({
  name: "variants",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setUserData(state, action) {
      state.userData = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subscribeToVariants = () => {
  if (variantsSubscription) {
    return;
  }
  try {
    variantsSubscription = onSnapshot(
      query(collection(db, "servicesVariants"), where("isDeleted", "!=", true)),
      (data) => {
        const variants: ServiceVariant[] = [];
        data.forEach((doc) => {
          variants.push({
            ...(doc.data() as ServiceVariant),
            id: doc.id,
          });
        });
        store.dispatch(
          variantsSlice.actions.setData(orderBy(variants, ["name"], ["asc"]))
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

const userVariantsSubscription = {
  sub: null as any,
  userId: "" as string,
};

export const subscribeToVariantsForUser = (userId: string) => {
  if (userVariantsSubscription.sub) {
    if (userVariantsSubscription.userId === userId) {
      return;
    } else {
      userVariantsSubscription.sub();
    }
  }
  try {
    userVariantsSubscription.sub = onSnapshot(
      query(
        collection(db, "userServicesVariants"),
        where("isDeleted", "!=", true),
        where("userId", "==", userId)
      ),
      (data) => {
        const variants: UserServiceVariant[] = [];
        data.forEach((doc) => {
          variants.push({
            ...(doc.data() as UserServiceVariant),
            id: doc.id,
            startDate: doc.data().startDate
              ? doc.data().startDate.toDate()
              : null,
          });
        });
        variants.sort((a, b) => {
          const getNestedProperty = (obj: UserServiceVariant, path: any) =>
            path
              .split(".")
              .reduce((acc: any, part: any) => acc && acc[part], obj);

          const isOnlineA = getNestedProperty(a, "variant.isOnline");
          const isOnlineB = getNestedProperty(b, "variant.isOnline");
          const priceA = a.price;
          const priceB = b.price;
          const serviceNameA = getNestedProperty(a, "service.name");
          const serviceNameB = getNestedProperty(b, "service.name");
          const variantNameA = getNestedProperty(a, "variant.name");
          const variantNameB = getNestedProperty(b, "variant.name");

          // Compare 'variant.isOnline', true first
          if (isOnlineA !== isOnlineB) {
            return isOnlineA ? 1 : -1;
          }

          // Compare 'price' if on 'osrodek' page
          if (window.location.href.includes("osrodek")) {
            if (priceA !== priceB) {
              return priceA > priceB ? 1 : -1;
            }
          }

          // Compare 'service.name' in descending order
          if (serviceNameA !== serviceNameB) {
            return serviceNameA > serviceNameB ? 1 : -1;
          }

          // Compare 'variant.name' in ascending order
          if (variantNameA !== variantNameB) {
            return variantNameA < variantNameB ? -1 : 1;
          }

          return 0; // if all comparisons are equal
        });
        store.dispatch(variantsSlice.actions.setUserData(variants));
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default variantsSlice.reducer;
