import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  where,
} from "firebase/firestore";
import moment from "moment-timezone";
import type { Appointment } from "practicare/types/appointments.model";
import type { Availability } from "practicare/types/availability.model";
import type { Customer } from "practicare/types/customer.model";
import type { Location } from "practicare/types/location.model";
import type { Note } from "practicare/types/notes.model";
import type { UserServiceVariant } from "practicare/types/service.model";
import type { TenantCalendarSettings } from "practicare/types/tenant.model";
import type {
  CustomerProblems,
  TherapyTypes,
  User,
} from "practicare/types/user.model";
import { calendarWorker } from "../../App";
import { db } from "../../config/firebase";
import { mapAvailability } from "../../mappers/availability";
import { store } from "../store";

interface AppointmentsDataConfig {
  appointmentsSub: (() => void) | null;
  appointmentStatus: string | null;
  availabilitySub: (() => void) | null;
  notesSub: (() => void) | null;
  startDateWeek: Date | boolean;
  endDateWeek: Date | boolean;
  selectedDateStart: Date | boolean;
  selectedDateEnd: Date | boolean;
  locationId: string | null;
  customerCategory: string | null;
  isAvailableStatus: string | null;
  theraphyTypes: any[];
  theraphyTypesIds: string[];
  customerProblemsIds: string[];
  theraphistIds: string[];
  theraphists: any[];
  users: any[];
  usersMap: Record<string, any>;
  calendarSettings: TenantCalendarSettings | null;
}

export let appointmentsDataConfig: AppointmentsDataConfig = {
  appointmentsSub: null,
  appointmentStatus: null,
  availabilitySub: null,
  notesSub: null,
  startDateWeek: false,
  endDateWeek: false,
  selectedDateStart: false,
  selectedDateEnd: false,
  locationId: null,
  customerCategory: null,
  isAvailableStatus: null,
  theraphyTypes: [],
  theraphyTypesIds: [],
  customerProblemsIds: [],
  theraphistIds: [],
  theraphists: [],
  users: [],
  usersMap: {},
  calendarSettings: null,
};

export interface CalendarState {
  userServiceVariants: UserServiceVariant[];
  userServiceCustomers: Customer[];
  appointmentsData: Appointment[];
  availabilityData: Availability[];
  updatedAt: string;
  locationId: string;
  notesData: any[];
  notesRaw: Note[];
  isLoading: boolean;
  isAvailLoading: boolean;
  isAppointLoading: boolean;
  calendarData: {
    events: any[];
    resources: any[];
    resourcesToShowByLocation: any[];
  };
}

const initialState: CalendarState = {
  userServiceVariants: [],
  userServiceCustomers: [],
  appointmentsData: [],
  availabilityData: [],
  updatedAt: Date.now().toString(),
  notesData: [],
  notesRaw: [],
  locationId: "",
  isLoading: true,
  isAvailLoading: true,
  isAppointLoading: true,
  calendarData: {
    events: [],
    resources: [],
    resourcesToShowByLocation: [],
  },
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    regenerateView: (state, action: PayloadAction<string>) => {
      state.locationId = action.payload;
      state.isLoading = true;
      if (calendarWorker) {
        calendarWorker.postMessage(
          JSON.stringify({
            appointments: state.appointmentsData,
            availability: state.availabilityData,
            locationId: action.payload,
            config: appointmentsDataConfig,
            websiteUrl: window?.location?.href,
          })
        );
      }
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setAppointmentsData: (
      state,
      action: PayloadAction<{ appointments: any[]; locationId: string }>
    ) => {
      state.appointmentsData = action.payload.appointments;
      state.locationId = action.payload.locationId;
      if (calendarWorker) {
        calendarWorker.postMessage(
          JSON.stringify({
            appointments: action.payload.appointments,
            availability: state.availabilityData,
            locationId: action.payload.locationId,
            config: appointmentsDataConfig,
            websiteUrl: window?.location?.href,
          })
        );
      }
    },
    setCalendarData: (
      state,
      action: PayloadAction<{ calendarData: any; locationId: string }>
    ) => {
      state.calendarData = action.payload.calendarData;
      state.locationId = action.payload.locationId;
      state.updatedAt = Date.now().toString();
      state.isLoading = false;
    },
    setAvailabilityData: (
      state,
      action: PayloadAction<{ availability: any[]; locationId: string }>
    ) => {
      state.availabilityData = action.payload.availability;
      state.locationId = action.payload.locationId;
      if (calendarWorker) {
        calendarWorker.postMessage(
          JSON.stringify({
            appointments: state.appointmentsData,
            availability: action.payload.availability,
            locationId: action.payload.locationId,
            config: appointmentsDataConfig,
            websiteUrl: window?.location?.href,
          })
        );
      }
    },
    setNotesData: (
      state,
      action: PayloadAction<{ notes: any[]; notesRaw: any[] }>
    ) => {
      state.notesData = action.payload.notes;
      state.notesRaw = action.payload.notesRaw;
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subscribeToNotes = () => {
  if (!appointmentsDataConfig.notesSub) {
    const constrainsAvailability: QueryConstraint[] = [
      where("isDeleted", "==", false),
    ];
    appointmentsDataConfig.notesSub = onSnapshot(
      query(collection(db, "calendarNotes"), ...constrainsAvailability),
      (data) => {
        try {
          store.dispatch(
            calendarSlice.actions.setNotesData({
              notesRaw: data.docs.map((d) => ({ id: d.id, ...d.data() })),
              notes: data.docs.map((d) => ({
                id: d.id,
                resourceId: d.data().userId,
                title: `${d.data().note}`,
                type: "NOTE",
                backgroundColor: "antiquewhite",
                borderColor: "lightgrey",
                fontSize: 5,
                allDay: true,
                start: d.data().singleDay
                  ? moment(d.data().displayDate.toDate())
                      .startOf("day")
                      .toDate()
                  : false,
                end: d.data().singleDay
                  ? moment(d.data().displayDate.toDate()).endOf("day").toDate()
                  : false,
                startRecur: !d.data().singleDay,
                endRecur: !d.data().singleDay,
                daysOfWeek: d.data().singleDay
                  ? false
                  : Object.keys(d.data()).reduce((acc: number[], key) => {
                      if (
                        d.data()[key] === true &&
                        key.indexOf("dayOfWeek") > -1
                      ) {
                        acc.push(Number(key.replace("dayOfWeek", "")) % 7);
                      }
                      return acc;
                    }, []),
              })),
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    );
  }
};

export interface CurrentCalendarSubscribeParams {
  dateStart: Date;
  dateEnd: Date;
  location: Location | null;
  theraphists: User[];
  theraphyTypes: TherapyTypes[];
  customerProblems: CustomerProblems[];
}

export const subscribeToCurrentCalendarView = (
  {
    dateStart,
    dateEnd,
    location,
    theraphists,
    theraphyTypes,
    customerProblems,
  }: CurrentCalendarSubscribeParams,
  usersData: User[],
  calendarSettings?: TenantCalendarSettings
) => {
  const locationId = location?.id;
  const theraphistIds = theraphists.map((t) => t.id);
  const theraphyTypesIds = theraphyTypes.map((t) => t.id);
  const customerProblemsIds = (customerProblems || []).map((t) => t.id);
  const startDateWeek = moment(dateStart).startOf("isoWeek").toDate();
  const endDateWeek = moment(dateEnd)
    .subtract("1", "second")
    .endOf("isoWeek")
    .toDate();
  const getMoreData =
    !moment(appointmentsDataConfig.startDateWeek as Date).isSame(
      moment(startDateWeek)
    ) ||
    !moment(appointmentsDataConfig.endDateWeek as Date).isSame(
      moment(endDateWeek)
    );
  const reinitiate =
    appointmentsDataConfig.locationId !== locationId ||
    JSON.stringify(theraphistIds) !==
      JSON.stringify(appointmentsDataConfig.theraphistIds) ||
    JSON.stringify(theraphyTypesIds) !==
      JSON.stringify(appointmentsDataConfig.theraphyTypesIds) ||
    JSON.stringify(customerProblemsIds) !==
      JSON.stringify(appointmentsDataConfig.customerProblemsIds) ||
    moment(appointmentsDataConfig.selectedDateStart as Date) !==
      moment(dateStart) ||
    moment(appointmentsDataConfig.selectedDateEnd as Date) !== moment(dateEnd);

  appointmentsDataConfig.selectedDateStart = moment(dateStart).toDate();
  appointmentsDataConfig.selectedDateEnd = moment(dateEnd).toDate();

  appointmentsDataConfig.theraphistIds = [...theraphistIds];
  appointmentsDataConfig.theraphyTypes = [...theraphyTypes];
  appointmentsDataConfig.theraphyTypesIds = [...theraphyTypesIds];
  appointmentsDataConfig.customerProblemsIds = [...customerProblemsIds];

  appointmentsDataConfig.usersMap = usersData.reduce(
    (p: Record<string, any>, c: any) => {
      p[c.id] = {
        ...c,
        theraphyTypeIds: c.theraphyTypes
          ? c.theraphyTypes.map((t: any) => t.id)
          : [],
        customerProblemsIds: c.customerProblems
          ? c.customerProblems.map((t: any) => t.id)
          : [],
      };

      return p;
    },
    {}
  );
  appointmentsDataConfig.endDateWeek = endDateWeek;
  appointmentsDataConfig.startDateWeek = startDateWeek;
  appointmentsDataConfig.locationId = locationId || "";
  appointmentsDataConfig.theraphists = theraphists;
  appointmentsDataConfig.calendarSettings = calendarSettings || {
    completedColor: "#28c76f",
    scheduledColor: "#3788d8",
    cancelledColor: "#ff9f43",
    cancelledPaidColor: "#ea5455",
    scheduledFirstTimeOfflineColor: "#d4af37",
    scheduledFirstTimeOnlineColor: "#abd437",
    reservationColor: "#455d7a",
    hideProblemsFilter: false,
    hideTheraphyTypesFilter: false,
    showAdminInDropdown: false,
    clearLocationAfterSelect: false,
  };

  if (getMoreData) {
    store.dispatch(calendarSlice.actions.setLoading());
    appointmentsDataConfig.startDateWeek = startDateWeek;
    appointmentsDataConfig.endDateWeek = endDateWeek;
    if (appointmentsDataConfig.appointmentsSub) {
      appointmentsDataConfig.appointmentsSub();
    }
    const constrainsAppointments: QueryConstraint[] = [
      where("isDeleted", "==", false),
    ];

    if (startDateWeek)
      constrainsAppointments.push(where("dateTime", ">=", startDateWeek));
    if (endDateWeek)
      constrainsAppointments.push(where("dateTime", "<=", endDateWeek));
    constrainsAppointments.push(orderBy("dateTime", "asc"));
    appointmentsDataConfig.appointmentsSub = onSnapshot(
      query(collection(db, "appointments"), ...constrainsAppointments),
      (data) => {
        try {
          store.dispatch(
            calendarSlice.actions.setAppointmentsData({
              appointments: data.docs.map((doc) => {
                return {
                  ...doc.data(),
                  id: doc.id,
                  dateTime: doc.data().dateTime.toDate(),
                };
              }),
              locationId: appointmentsDataConfig.locationId!,
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    );
  } else if (reinitiate) {
    store.dispatch(calendarSlice.actions.setLoading());
    store.dispatch(calendarSlice.actions.regenerateView(locationId!));
  }

  if (!appointmentsDataConfig.availabilitySub) {
    const constrainsAvailability: QueryConstraint[] = [
      where("isDeleted", "==", false),
    ];

    appointmentsDataConfig.availabilitySub = onSnapshot(
      query(collection(db, "availability"), ...constrainsAvailability),
      (data) => {
        try {
          store.dispatch(
            calendarSlice.actions.setAvailabilityData({
              availability: data.docs.map((d) => mapAvailability(d)),
              locationId: appointmentsDataConfig.locationId!,
            })
          );
        } catch (e) {
          console.error(e);
        }
      }
    );
  }

  subscribeToNotes();
};

export const unsubscribeFromCurrentCalendarView = () => {
  if (appointmentsDataConfig.appointmentsSub) {
    appointmentsDataConfig.appointmentsSub();
    appointmentsDataConfig.appointmentsSub = null;
  }
  if (appointmentsDataConfig.availabilitySub) {
    appointmentsDataConfig.availabilitySub();
    appointmentsDataConfig.availabilitySub = null;
  }
  appointmentsDataConfig = {
    appointmentsSub: null,
    appointmentStatus: null,
    availabilitySub: null,
    notesSub: null,
    startDateWeek: false,
    endDateWeek: false,
    selectedDateStart: false,
    selectedDateEnd: false,
    locationId: null,
    customerCategory: null,
    isAvailableStatus: null,
    theraphyTypes: [],
    theraphyTypesIds: [],
    customerProblemsIds: [],
    theraphistIds: [],
    theraphists: [],
    users: [],
    usersMap: {},
    calendarSettings: null,
  };
};

export default calendarSlice.reducer;
