import classNames from "classnames";
import { Control, Controller } from "react-hook-form";
import Select, { OptionsOrGroups } from "react-select";
import { FormFeedback, Label } from "reactstrap";
import { getOptionLabel, getOptionValue } from "./utils";

interface RenderSelectFieldProps {
  name: string;
  label: string;
  placeholder: string;
  isMulti: boolean;
  required?: boolean;
  disabled?: boolean;
  control: Control<any>;
  errors: any;
  isClearable?: boolean;
  options: OptionsOrGroups<any, any>;
  getOptionLabelFnc?: (option: any) => string;
  getOptionValueFnc?: (option: any) => string;
  isOptionDisabledFnc?: (option: any) => boolean;
  onChangeFnc?: (e: any) => void;
  components?: any;
  defaultValue?: any;
  styles?: any;
}

export const renderSelectField: React.FC<RenderSelectFieldProps> = ({
  name,
  label,
  placeholder,
  required,
  isMulti,
  control,
  disabled,
  errors,
  options,
  isClearable,
  getOptionLabelFnc = getOptionLabel,
  getOptionValueFnc = getOptionValue,
  isOptionDisabledFnc,
  onChangeFnc,
  components,
  defaultValue,
  styles,
}) => {
  return (
    <div
      className={classNames({
        ["mb-1"]: !styles?.hideMargin,
        ["w-50"]: styles?.width50,
      })}
    >
      <Label className="form-label" for={name}>
        {label} {required && <span className="text-danger">*</span>}
      </Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            isMulti={isMulti}
            placeholder={placeholder}
            isClearable={isClearable}
            isDisabled={disabled}
            isOptionDisabled={isOptionDisabledFnc}
            options={options}
            getOptionLabel={getOptionLabelFnc}
            getOptionValue={getOptionValueFnc}
            classNamePrefix="select"
            className={classNames("react-select", {
              "is-invalid": errors[name],
            })}
            onChange={(e) => {
              if (onChangeFnc) {
                onChangeFnc(e);
              }
              field.onChange(e);
            }}
            components={components}
            defaultValue={defaultValue ? defaultValue : undefined}
          />
        )}
      />
      <FormFeedback>{errors[name]?.message}</FormFeedback>
    </div>
  );
};
