import {
  Briefcase,
  Calendar,
  Grid,
  Home,
  Layers,
  List,
  MapPin,
  Send,
  Settings,
  User,
  UserCheck,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { BsQuestionCircle } from "react-icons/bs";
import { FaCashRegister, FaPlane } from "react-icons/fa";
import { TbFileInvoice } from "react-icons/tb";

export const navBarItems = () => {
  const { t } = useTranslation();
  const navBar: any[] = [
    {
      id: "me",
      title: t("My Profile"),
      requiredPermission: ["ADMIN", "THERAPHIST"],
      icon: <User size={20} />,
      navLink: "/me",
    },
    {
      id: "calendar",
      title: t("Calendar"),
      requiredPermission: ["ADMIN"],
      icon: <Calendar size={20} />,
      navLink: "/calendar",
    },
    {
      id: "myCalendar",
      title: t("My Calendar"),
      requiredPermission: ["THERAPHIST"],
      icon: <Calendar size={20} />,
      navLink: "/myCalendar",
    },
    {
      id: "appointments",
      title: t("Appointments"),
      requiredPermission: ["ADMIN", "THERAPHIST"],
      icon: <Briefcase size={20} />,
      navLink: "/appointments",
    },
    {
      id: "availability",
      title: t("Schedule"),
      requiredPermission: ["ADMIN"],
      icon: <Grid size={20} />,
      navLink: "/timeTable",
    },
    {
      id: "holidays",
      title: t("Holidays"),
      requiredPermission: ["ADMIN"],
      icon: <FaPlane size={20} />,
      navLink: "/holidays",
    },
    {
      id: "customers",
      title: t("Customers"),
      requiredPermission: ["ADMIN", "THERAPHIST"],
      icon: <UserCheck size={20} />,
      navLink: "/customers",
    },
    {
      id: "payments",
      title: t("Payments"),
      requiredPermission: ["ADMIN", "THERAPHIST"],
      icon: <FaCashRegister size={20} />,
      navLink: "/payments",
    },
    {
      id: "invoices",
      title: t("Invoices"),
      requiredPermission:
        window.location.href.indexOf("atmaia") > -1
          ? ["ADMIN", "THERAPHIST"]
          : ["ADMIN"],
      icon: <List size={20} />,
      navLink: "/invoices",
    },
    {
      id: "payroll",
      title: t("Payroll"),
      requiredPermission: !(window.location.href.indexOf("atmaia") > -1)
        ? ["ADMIN", "THERAPHIST"]
        : ["ADMIN"],
      icon: <TbFileInvoice size={20} />,
      navLink: "/payroll",
    },
    {
      id: "FAQ",
      title: "FAQ",
      requiredPermission: ["ADMIN", "THERAPHIST"],
      icon: <BsQuestionCircle size={20} />,
      navLink: "/faq",
    },
    {
      id: "messages",
      title: t("Messaging"),
      requiredPermission: ["ADMIN"],
      icon: <Send size={20} />,
      navLink: "/messages",
    },
    {
      id: "settings",
      title: t("Settings"),
      requiredPermission: ["ADMIN"],
      icon: <Settings size={20} />,
      children: [
        {
          id: "users",
          title: t("Users"),
          requiredPermission: ["ADMIN"],
          icon: <User size={20} />,
          navLink: "/users",
        },
        {
          id: "locations",
          title: t("Locations"),
          requiredPermission: ["ADMIN"],
          icon: <MapPin size={20} />,
          navLink: "/locations",
        },
        {
          id: "rooms",
          title: t("Rooms"),
          requiredPermission: ["ADMIN"],
          icon: <Home size={20} />,
          navLink: "/rooms",
        },
        {
          id: "services",
          title: t("Services"),
          requiredPermission: ["ADMIN"],
          icon: <Layers size={20} />,
          navLink: "/services",
        },
        {
          id: "types",
          title:
            window.location.href.indexOf("atmaia") > -1
              ? t("Certificates")
              : t("Therapeutic Approaches"),
          requiredPermission: ["ADMIN"],
          icon: <List size={20} />,
          navLink: "/types",
        },
        {
          id: "sources",
          title: t("Customer Sources"),
          requiredPermission: ["ADMIN"],
          icon: <List size={20} />,
          navLink: "/sources",
        },
        {
          id: "problems",
          title: t("Customer Issues"),
          requiredPermission: ["ADMIN"],
          icon: <List size={20} />,
          navLink: "/problems",
        },
        {
          id: "coupons",
          title: t("Coupons"),
          requiredPermission: ["ADMIN"],
          icon: <List size={20} />,
          navLink: "/coupons",
        },
        {
          id: "settings",
          title: t("System"),
          requiredPermission: ["ADMIN"],
          icon: <List size={20} />,
          navLink: "/settings",
        },
      ],
    },
  ];

  if (window.location.href.includes("studio")) {
    const calendarLocation = {
      id: "calendarLocation",
      title: t("Location Calendar"),
      requiredPermission: ["ADMIN"],
      icon: <Calendar size={20} />,
      navLink: "/calendarLocation",
    };
    navBar.splice(2, 0, calendarLocation);
  }
  return navBar;
};
