import { Outlet } from "react-router-dom";
import { navBarItems } from "../router/navbar";
import Layout from "./verticalLayout";

const VerticalLayout = (props) => {
  return (
    <Layout menuData={navBarItems()} {...props}>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
