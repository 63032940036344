import { default as classNames, default as classnames } from "classnames";
import type { i18n } from "i18next";
import Flatpickr from "react-flatpickr";
import { Controller } from "react-hook-form";
import { FormFeedback, Label } from "reactstrap";
import { selectFlatpickrLocale } from "./utils";
interface RenderDatePickerProps {
  name: string;
  label: string;
  control: any;
  errors: any;
  watch: any;
  setValue: any;
  placeholder?: string;
  showClearButton?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange?: any;
  dateFormat?: string;
  enableTime?: boolean;
  required?: boolean;
  noCalendar?: boolean;
  minTime?: Date | null;
  maxTime?: Date | null;
  styles?: any;
  i18n: i18n;
}
export const renderDatePicker = ({
  name,
  label,
  control,
  errors,
  watch,
  setValue,
  placeholder,
  showClearButton,
  minDate,
  maxDate,
  onChange,
  dateFormat = "Y-m-d",
  enableTime = false,
  required = false,
  noCalendar = false,
  minTime = null,
  maxTime = null,
  styles,
  i18n,
}: RenderDatePickerProps) => {
  return (
    <div
      className={classNames({
        ["mb-1"]: !styles?.hideMargin,
        ["w-50"]: styles?.width50,
      })}
      style={{ position: "relative" }}
    >
      <Label className="form-label" for={name}>
        {label} {required && <span className="text-danger">*</span>}
      </Label>
      <Controller
        control={control}
        name={name}
        render={({ field }: { field: any }) => (
          <Flatpickr
            {...field}
            placeholder={placeholder}
            options={{
              noCalendar,
              firstDayOfWeek: 1,
              enableTime,
              dateFormat,
              minuteIncrement: 30,
              locale: selectFlatpickrLocale(i18n),
              minDate: minDate,
              maxDate: maxDate,
              minTime: minTime,
              maxTime: maxTime,
            }}
            className={classnames("form-control", {
              "is-invalid": errors[name],
            })}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              field.onChange(e && e.length ? e[0] : null);
            }}
          />
        )}
      />
      {showClearButton && !!watch(name) && (
        <span
          onClick={() => setValue(name, null)}
          style={{ position: "absolute", right: 42, top: 32 }}
        >
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        </span>
      )}
      {errors[name] && <FormFeedback>{errors[name].message}</FormFeedback>}
    </div>
  );
};
