import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
  type QueryConstraint,
} from "firebase/firestore";
import { orderBy, sortBy } from "lodash";
import type { Appointment } from "practicare/types/appointments.model";
import type {
  Customer,
  CustomerSource,
} from "practicare/types/customer.model";
import { db } from "../../config/firebase";
import { getCollectionName } from "../../config/utils";
import { store } from "../store";

export interface CustomersState {
  data: Customer[];
  customersForUser: Customer[];
  customerSources: CustomerSource[];
  singleCustomer: Customer | null;
  updatedAt: string;
}

// Define initial state
const initialState: CustomersState = {
  data: [],
  customersForUser: [],
  customerSources: [],
  singleCustomer: null,
  updatedAt: Date.now().toString(),
};

export const customersSubscription = {
  sub: null as (() => void) | null,
  start: null as Date | null,
  loading: false as boolean,
};

export const singleCustomerSub = {
  sub: null as (() => void) | null,
  customerId: "" as string,
  loading: true as boolean,
};

export const customersUserSubscription = {
  sub: null as (() => void) | null,
  start: null as Date | null,
  loading: false as boolean,
  userId: "" as string,
};

const customerSourcesSubscription = {
  sub: null as (() => void) | null,
};
export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomersForUser: (state, action: PayloadAction<Customer[]>) => {
      state.customersForUser = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setCustomerProblems: (state, action: PayloadAction<CustomerSource[]>) => {
      state.customerSources = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setSingleCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.singleCustomer = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

// Export action creators
export const { setCustomersForUser, setCustomers } = customersSlice.actions;
export const subscribeToCustomersForUser = (
  userId: string,
  isAdmin: boolean
) => {
  if (!userId) {
    return;
  }
  if (customersUserSubscription.sub) {
    if (userId && customersUserSubscription.userId !== userId) {
      customersUserSubscription.sub();
      customersUserSubscription.userId = userId;
    } else {
      return;
    }
  }
  customersUserSubscription.loading = true;
  const collectionName = getCollectionName("customers", store, isAdmin);
  try {
    customersUserSubscription.sub = onSnapshot(
      query(
        collection(db, collectionName),
        where(`assignedUsersIdMap.${userId}`, "==", true)
      ),
      (data) => {
        const customers: Customer[] = [];
        data.forEach((doc) => {
          customers.push({
            ...(doc.data() as Customer),
            id: doc.id,
          });
        });
        const sortedCustomers = sortBy(
          customers,
          ["lastName", "firstName"],
          ["asc", "asc"]
        );
        store.dispatch(
          customersSlice.actions.setCustomersForUser(
            sortedCustomers.filter((s) => !s.isDeleted)
          )
        );
        customersUserSubscription.loading = false;
      }
    );
  } catch (e: any) {
    customersUserSubscription.loading = false;
    console.error(e);
  }
};

export const callCustomersSubscription = () => {
  if (customersSubscription.sub) {
    return;
  }
  customersSubscription.loading = true;
  try {
    customersSubscription.sub = onSnapshot(
      query(collection(db, "customers"), where("isDeleted", "==", false)),
      (data) => {
        const customers: Customer[] = [];
        data.forEach((doc) => {
          customers.push({
            ...(doc.data() as Customer),
            id: doc.id,
          });
        });
        const sortedCustomers = sortBy(
          customers,
          ["lastName", "firstName"],
          ["asc", "asc"]
        );
        store.dispatch(customersSlice.actions.setCustomers(sortedCustomers));
        customersSubscription.loading = false;
      }
    );
  } catch (e: any) {
    customersSubscription.loading = false;
    console.error(e);
  }
};
export const subscribeToCustomerSources = () => {
  if (customerSourcesSubscription.sub) {
    return;
  }
  try {
    customerSourcesSubscription.sub = onSnapshot(
      query(collection(db, "customerSources"), where("isDeleted", "!=", true)),
      (data) => {
        const customerSources: CustomerSource[] = [];
        data.forEach((doc) => {
          customerSources.push({
            ...(doc.data() as CustomerSource),
            id: doc.id,
          });
        });
        const ordered = orderBy(customerSources, ["name"], ["asc"]);
        store.dispatch(customersSlice.actions.setCustomerProblems(ordered));
        return customerSources;
      }
    );
  } catch (e) {
    console.error(e);
  }
};
export const fetchUserCustomers = async (userId: string, isAdmin: boolean) => {
  try {
    const constraints: QueryConstraint[] = [
      where("isDeleted", "==", false),
      where(`assignedUsersIdMap.${userId}`, "==", true),
    ];
    const collectionName = getCollectionName("customers", store, isAdmin);
    const q = query(collection(db, collectionName), ...constraints);
    const querySnapshot = await getDocs(q);
    const customers: Customer[] = [];
    querySnapshot.forEach((doc) => {
      if (!doc.data().isDeleted) {
        customers.push({
          ...(doc.data() as Customer),
          id: doc.id,
        });
      }
    });
    return customers;
  } catch (e) {
    console.error(e);
    return [];
  }
};
export const fetchCustomerAppointments = async (
  customerId: string,
  isAdmin: boolean,
  userId: string
) => {
  try {
    const collectionName = getCollectionName("appointments", store, isAdmin);
    const constrains: QueryConstraint[] = [
      where("customer.id", "==", customerId),
      where("isDeleted", "!=", true),
    ];
    if (!isAdmin) {
      constrains.push(where("theraphist.id", "==", userId));
    }
    const q = query(collection(db, collectionName), ...constrains);
    const querySnapshot = await getDocs(q);
    const appointments: Appointment[] = [];
    querySnapshot.forEach((doc) => {
      if (!doc.data().isDeleted) {
        appointments.push({
          ...(doc.data() as Appointment),
          dateTime: doc.data().dateTime.toDate(),
          id: doc.id,
        });
      }
    });
    return appointments;
  } catch (e) {
    console.error(e);
    return [];
  }
};
export const subscribeToSingleCustomer = (id: string, isAdmin: boolean) => {
  if (singleCustomerSub.sub) {
    if (singleCustomerSub.customerId !== id) {
      singleCustomerSub.sub();
    } else {
      return;
    }
  }
  singleCustomerSub.customerId = id;
  singleCustomerSub.loading = true;
  store.dispatch(customersSlice.actions.setSingleCustomer(null));
  const collectionName = getCollectionName("customers", store, isAdmin);
  try {
    singleCustomerSub.sub = onSnapshot(doc(db, collectionName, id), (data) => {
      const customer = data.exists()
        ? {
            ...(data.data() as Customer),
            id: data.id,
          }
        : null;
      singleCustomerSub.loading = false;
      store.dispatch(customersSlice.actions.setSingleCustomer(customer));
    });
  } catch (e: any) {
    console.error(e);
  }
};
export default customersSlice.reducer;
