// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { Note } from "practicare/types/notes.model";
import { db } from "../../config/firebase";
import { store } from "../store";

export interface NotesState {
  publicData: Note[];
  privateData: Note[];
  updatedAt: string;
}

const initialState: NotesState = {
  publicData: [],
  privateData: [],
  updatedAt: Date.now().toString(),
};

// ** Subscriptions
let notesSubscriptionPrivate: (() => void) | null = null;
let notesSubscriptionPublic: (() => void) | null = null;

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setPublicData(state, action: PayloadAction<Note[]>) {
      state.publicData = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setPrivateData(state, action: PayloadAction<Note[]>) {
      state.privateData = action.payload;
      state.updatedAt = Date.now().toString();
    },
    clearData(state) {
      state.publicData = [];
      state.privateData = [];
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subscribeToNotes = (documentId: string, userId: string) => {
  store.dispatch(notesSlice.actions.clearData());
  if (notesSubscriptionPrivate) {
    notesSubscriptionPrivate();
  }
  if (notesSubscriptionPublic) {
    notesSubscriptionPublic();
  }

  notesSubscriptionPrivate = onSnapshot(
    query(
      collection(db, "notes"),
      where("documentId", "==", documentId),
      where("createdBy.id", "==", userId),
      where("isPublic", "==", false),
      where("isDeleted", "==", false)
    ),
    (data) => {
      const notes: Note[] = [];

      data.forEach((doc) => {
        notes.push({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt
            ? doc.data().createdAt.toDate()
            : null,
        } as Note);
      });
      store.dispatch(
        notesSlice.actions.setPublicData(orderBy(notes, ["createdAt"], ["asc"]))
      );
    }
  );

  notesSubscriptionPublic = onSnapshot(
    query(
      collection(db, "notes"),
      where("documentId", "==", documentId),
      where("isPublic", "==", true),
      where("isDeleted", "==", false)
    ),
    (data) => {
      const notes: Note[] = [];
      data.forEach((doc) => {
        notes.push({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt
            ? doc.data().createdAt.toDate()
            : null,
        } as Note);
      });
      store.dispatch(
        notesSlice.actions.setPrivateData(
          orderBy(notes, ["createdAt"], ["asc"])
        )
      );
    }
  );
};

export default notesSlice.reducer;
