import classNames from "classnames";
import { get } from "lodash";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { Label } from "reactstrap";

export const renderPhoneNumber = (
  name: string,
  label: string,
  required: boolean,
  control: any,
  errors: any,
  isDisabled?: boolean,
  styles?: any,
  hideLabel?: boolean
) => (
  <div
    className={classNames({
      ["mb-1"]: !styles?.hideMargin,
      ["w-50"]: styles?.width50,
      ["w-25"]: styles?.width25,
    })}
  >
    {!hideLabel && (
      <Label className="form-label" for={name}>
        {label} {required && <span className="text-danger">*</span>}
      </Label>
    )}
    <PhoneInputWithCountry
      name={name}
      placeholder={label}
      disabled={isDisabled}
      control={control}
      defaultCountry="PL"
      international
      withCountryCallingCode
      className="form-control"
      style={{ display: "flex" }}
    />
    {get(errors, name + ".message", "") && (
      <div
        style={{
          width: "100%",
          marginTop: "0.25rem",
          fontSize: "0.857rem",
          color: "#ea5455",
        }}
      >
        {get(errors, name + ".message", "")}
      </div>
    )}
  </div>
);
