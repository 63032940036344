import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { CustomerInvoice } from "practicare/types/customerInvoice.model";
import type { User } from "practicare/types/user.model";
import { db } from "../../config/firebase";
import { store } from "../store";

export interface InvoicesState {
  data: CustomerInvoice[];
}

export const invoicesSubscription = {
  sub: null as (() => void) | null,
  start: null as Date | null,
  end: null as Date | null,
  loading: false as boolean,
  userId: "" as string,
  theraphistId: "" as string,
};

const initialState: InvoicesState = {
  data: [],
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<CustomerInvoice[]>) => {
      state.data = action.payload;
    },
  },
});

export const subToInvoices = (
  start: Date | null,
  end: Date | null,
  user: User | null
) => {
  invoicesSubscription.loading = true;

  const shouldUnsubscribe =
    invoicesSubscription.sub &&
    ((start && invoicesSubscription.start?.getTime() !== start.getTime()) ||
      (end && invoicesSubscription.end?.getTime() !== end.getTime()) ||
      (user && invoicesSubscription.userId !== user.id));

  if (invoicesSubscription.sub) {
    if (shouldUnsubscribe) {
      if (invoicesSubscription.sub) {
        invoicesSubscription.sub();
      }
    } else {
      invoicesSubscription.loading = false;
      return;
    }
  }

  store.dispatch(invoicesSlice.actions.setData([]));
  invoicesSubscription.start = start;
  invoicesSubscription.end = end;
  invoicesSubscription.userId = user?.id || "";

  const constraints = [where("isDeleted", "==", false)];

  if (start) constraints.push(where("createdAt", ">=", start));
  if (end) constraints.push(where("createdAt", "<=", end));
  if (user && user.role !== "ADMIN") {
    constraints.push(where("theraphist.id", "==", user.id));
  }

  try {
    invoicesSubscription.sub = onSnapshot(
      query(collection(db, "generatedCustomerInvoices"), ...constraints),
      (snapshot) => {
        const invoices: CustomerInvoice[] = snapshot.docs.map((doc) => ({
          ...(doc.data() as CustomerInvoice),
          id: doc.id,
          dateTime: doc.data().createdAt?.toDate(),
        }));
        const orderedInvoices = orderBy(invoices, "dateTime", "desc");
        store.dispatch(invoicesSlice.actions.setData(orderedInvoices));
        invoicesSubscription.loading = false;
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export default invoicesSlice.reducer;
