import classnames from "classnames";

import { Badge } from "reactstrap";

export const Avatar = (props) => {
  // ** Props
  const {
    img,
    size,
    icon,
    color,
    status,
    badgeUp,
    content,
    tag,
    initials,
    imgWidth,
    className,
    badgeText,
    imgHeight,
    badgeColor,
    imgClassName,
    contentStyles,
    isCustom,
    ...rest
  } = props;

  // ** Function to extract initials from content
  const getInitials = (str) => {
    const results = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      results.push(e[0]);
    });
    return results.join("");
  };

  let customSize = 35;

  if (size === "xl") {
    customSize = 80;
  }
  const Tag = tag ? tag : "div";
  return !isCustom ? (
    <Tag
      className={classnames("avatar", {
        [className]: className,
        [`bg-${color}`]: color,
        [`avatar-${size}`]: size,
      })}
      {...rest}
    >
      {img === false || img === undefined ? (
        <span
          className={classnames("avatar-content", {
            "position-relative": badgeUp,
          })}
          style={contentStyles}
        >
          {initials ? getInitials(content) : content}

          {icon ? icon : null}
          {badgeUp ? (
            <Badge
              color={badgeColor ? badgeColor : "primary"}
              className="badge-sm badge-up"
              pill
            >
              {badgeText ? badgeText : "0"}
            </Badge>
          ) : null}
        </span>
      ) : (
        <img
          className={classnames({
            [imgClassName]: imgClassName,
          })}
          src={img}
          alt="avatarImg"
          height={imgHeight && !size ? imgHeight : 32}
          width={imgWidth && !size ? imgWidth : 32}
        />
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size,
          })}
        ></span>
      ) : null}
    </Tag>
  ) : (
    <div
      style={{
        display: "block",
        position: "relative",
        overflow: "hidden",
        width: `${customSize}px`,
        height: `${customSize}px`,
        lineHeight: `${customSize}px`,
        textAlign: "center",
        borderRadius: "50%",
        // minWidth: `${customSize}px`,
        minHeight: `${customSize}px`,
        maxWidth: `${customSize}px`,
        maxHeight: `${customSize}px`,
      }}
    >
      <img
        alt="user-avatar"
        src={img}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          verticalAlign: "middle",
          transform: "scale(1.45)",
        }}
      />
    </div>
  );
};
