import type { Timestamp } from "firebase/firestore";
import type { Availability } from "practicare/types/availability.model";

interface MappedAppointmentTheraphist {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  comment: string;
  isHiddenFromCalendar: boolean;
}

interface MappedAppointmentLocation {
  id: string;
  name: string;
}

interface Room {
  id: string;
  name: string;
}

export interface MappedAvailability {
  id: string;
  startDate: Date | null;
  endDate: Date | null;
  startHour: Date | null;
  endHour: Date | null;
  type: { value: string; label: string };
  isDeleted: boolean;
  description: string;
  calendarLabel: any;
  theraphist: MappedAppointmentTheraphist;
  dayOfWeek: number[];
  location: MappedAppointmentLocation | null;
  room: Room | null;
}
export const mapAvailability = (firebaseData: any): Availability | null => {
  const availability: Availability = firebaseData.data
    ? firebaseData.data()
    : firebaseData;
  try {
    const mappedAvail: Availability = {
      id: firebaseData.id,
      startDate: (availability.startDate as Timestamp)?.toDate(),
      endDate: (availability.endDate as Timestamp)?.toDate(),
      startHour: (availability.startHour as Timestamp)?.toDate(),
      endHour: (availability.endHour as Timestamp)?.toDate(),
      type: availability.type
        ? availability.type
        : {
            value: "WORKING_HOURS",
            label: "Godziny pracy",
          },
      isDeleted: !!availability.isDeleted,
      description: availability.description || "",
      calendarLabel: availability.calendarLabel || null,
      theraphist: {
        id: availability.theraphist.id,
        firstName: availability.theraphist.firstName,
        lastName: availability.theraphist.lastName,
        avatar:
          availability.theraphist.avatar ||
          "https://practicare-storage.s3.eu-central-1.amazonaws.com/assets/avatar.png",
        comment: availability.theraphist.comment || "",
        isHiddenFromCalendar: Boolean(
          availability.theraphist.isHiddenFromCalendar
        ),
      },
      dayOfWeek: availability.dayOfWeek as any,
      location: availability.location?.id
        ? {
            id: availability.location.id,
            name: availability.location.name as string,
          }
        : null,
      room: availability.room?.id
        ? {
            id: availability.room?.id,
            name: availability.room?.name,
          }
        : null,
    };
    return mappedAvail;
  } catch (e) {
    console.error("Error mapping avail", firebaseData, firebaseData.id);
    return null;
  }
};

import moment from "moment-timezone";

export const mapAvailabilityExcel = (list: Availability[]) => {
  const mappedList: any[] = [];
  list.forEach((item) => {
    mappedList.push({
      ID: item.id,
      "Data od": moment(item.startDate).format("YYYY-MM-DD"),
      "Data do": moment(item.endDate).format("YYYY-MM-DD"),
      "Godzina od": moment(item.startHour).format("HH:mm"),
      "Godzina do": moment(item.endHour).format("HH:mm"),
      Typ: item.type?.label,
      Opis: item.description,
      "Lokalizacja - ID": item.location?.id,
      "Lokalizacja - Nazwa": item.location?.name,
      "Gabinet - ID": item.room?.name,
      "Gabinet - Nazwa": item.room?.name,
      "Terapeuta - ID": item?.theraphist?.id,
      "Terapeuta - Imię": item?.theraphist?.firstName,
      "Terapeuta - Nazwisko": item?.theraphist?.lastName,
      "Dzień tygodnia": item.dayOfWeek?.value,
      Komentarz: item.theraphist.comment,
      "Terapeuta ukryty z kalendarza": item.theraphist.isHiddenFromCalendar
        ? "Tak"
        : "Nie",
    });
  });
  return mappedList;
};
