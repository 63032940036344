import classnames from "classnames";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
// ** Icons Imports
import { Circle, Disc, X } from "react-feather";

// ** Config

// ** Utils
import { useTenant } from "src/context/tenant";
import {
  getHomeRouteForLoggedInUser,
  getUserData,
} from "../../../../config/utils";

const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover,
  } = props;

  // ** Vars
  const user = getUserData();
  const { tenantSettings } = useTenant();
  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed, tenantSettings.companyLogoUrl]);

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      );
    } else {
      return (
        <Circle
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      );
    }
  };

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <NavLink
            to={user ? getHomeRouteForLoggedInUser(user.role) : "/"}
            className="navbar-brand"
          >
            <span className={classnames("brand-logo")}>
              <img
                src={tenantSettings.companyLogoUrl}
                alt="logo"
                style={{ maxWidth: "150px", maxHeight: "50px" }}
              />
            </span>
          </NavLink>
        </li>
        {(window.location.href.indexOf("inspo") === -1 ||
          window.location.href.indexOf("atmaia") === -1 ||
          window.location.href.indexOf("osrodek")) === -1 ? (
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle cursor-pointer">
              <Toggler />
              <X
                onClick={() => setMenuVisibility(false)}
                className="toggle-icon icon-x d-block d-xl-none"
                size={20}
              />
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default VerticalMenuHeader;
