import { Fragment } from "react";

import classnames from "classnames";

import { Spinner } from "reactstrap";

// ** Styles
import "./ui-loader.scss";

const UILoader = ({
  children,
  blocking = false,
  loader = <Spinner color="primary" />,
  className,
  tag = "div",
  overlayColor,
}: any) => {
  const Tag = tag;

  return (
    <Tag
      className={classnames("ui-loader", {
        [className]: className,
        show: blocking,
      })}
    >
      {children}
      {blocking ? (
        <Fragment>
          <div
            className="overlay" /*eslint-disable */
            {...(blocking && overlayColor
              ? { style: { backgroundColor: overlayColor } }
              : {})}
            /* eslint-enable */
          ></div>
          <div className="loader">{loader}</div>
        </Fragment>
      ) : null}
    </Tag>
  );
};

export default UILoader;
