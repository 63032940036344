import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { Coupon } from "practicare/types/coupons.model";
import { db } from "../../config/firebase";
import { store } from "../store";

export interface CouponsState {
  data: Coupon[];
}

let couponsSub: (() => void) | null = null;

const initialState: CouponsState = {
  data: [],
};

export const couponsSlice = createSlice({
  name: "coupons",
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Coupon[]>) => {
      state.data = action.payload;
    },
  },
});

export const subToCoupons = () => {
  if (couponsSub) {
    return;
  }
  try {
    couponsSub = onSnapshot(
      query(collection(db, "coupons"), where("isDeleted", "!=", true)),
      (data) => {
        const coupons: Coupon[] = [];
        data.forEach((doc) => {
          coupons.push({
            ...(doc.data() as Coupon),
            id: doc.id,
          });
        });
        const orderedLocations = orderBy(coupons, ["name"], ["asc"]);
        store.dispatch(couponsSlice.actions.setData(orderedLocations));
      }
    );
  } catch (e: any) {
    console.error(e);
  }
};

export default couponsSlice.reducer;
